"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, Fragment, useState } from "react";
import { CiHome, CiSearch } from "react-icons/ci";
import { CiMenuBurger } from "react-icons/ci";
import { TfiClose, TfiAngleRight, TfiAngleLeft } from "react-icons/tfi";
import { useParams, usePathname } from "next/navigation";

import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";

export const Header = ({ data, options }) => {
	const [jsCode, setJsCode] = useState("");
	const [headerData, setHeaderData] = useState("");
	const [headerDataT, setHeaderDataT] = useState("");

	const [navOpen, setNavOpen] = useState(false);

	const params = useParams();
	const _pathname = usePathname();
	const pathname = _pathname.slice(1);

	let currentRoute;

	try {
		currentRoute = pathname;
	} catch (e) {}

	useEffect(() => {
		const slickArrows = document.querySelectorAll(".slick-arrow");
		slickArrows.forEach((arrow) => {
			arrow.classList.add("tabindexlink");
		});

		const slickDots = document.querySelectorAll(".slick-dots li button");
		slickDots.forEach((dot) => {
			dot.classList.add("tabindexlink");
		});
	}, []);

	const [parentPage, setParentPage] = useState("");

	useEffect(() => {
		if (currentRoute != "/") {
			currentRoute = params.slug;
		}
		console.log(pathname);

		setParentPage("");
	}, [currentRoute]);

	let childrenMap = [];

	if (!pathname.includes("/news/")) {
		childrenMap = data.items.map((menuItem) => {
			const childTitle = "";
			const childSlug = "";

			if (menuItem.child_items) {
				return menuItem.child_items.map((childItem) => {
					if (menuItem.slug === currentRoute) {
						const RenderLink = childItem?.url?.includes("news") ? "a" : Link;
						return (
							<li
								className="navItemChild lg:w-1/2 list-none indent-0"
								key={childItem.slug + childItem.url}
							>
								<RenderLink
									aria-label={childItem.title}
									tabIndex={navOpen ? "0" : "-1"}
									onClick={() => setNavOpen(false)}
									className="hover:translate-x-[5px] transitionMe text-[18px] pl-[15px] py-[10px] mb-[10px] border-[#7E2D87] border-b-2"
									href={childItem.slug ?? childItem.url}
								>
									<span dangerouslySetInnerHTML={{ __html: childItem.title }} />
								</RenderLink>
							</li>
						);
					}
					if (childItem.slug === currentRoute) {
						return data.items.map((menuItem) => {
							if (Number(menuItem.ID) === Number(childItem.menu_item_parent)) {
								if (parentPage === "") {
									setParentPage(menuItem.object_id);
								}
								return menuItem.child_items.map((childItem) => {
									return (
										<li
											className="lg:w-1/2 list-none indent-0"
											key={childItem.slug + childItem.url}
										>
											<Link
												aria-label={childItem.title}
												tabIndex={navOpen ? "0" : "-1"}
												onClick={() => setNavOpen(false)}
												className={
													currentRoute === childItem.slug
														? "hover:translate-x-[5px] transitionMe text-[18px]  py-[10px] pl-[15px]  mb-[10px] border-[#7E2D87] border-b-2 font-bold "
														: "hover:translate-x-[5px] transitionMe text-[18px]  py-[10px] pl-[15px]  mb-[10px] border-[#7E2D87] border-b-2"
												}
												href={childItem.slug ?? childItem.url}
											>
												<span
													dangerouslySetInnerHTML={{ __html: childItem.title }}
												/>
											</Link>
										</li>
									);
								});
							}
						});
					}
				});
			}
		});
	}

	return (
		<>
			{/* CLOSED NAV  */}
			<nav className="bg-[#fff] z-[5] relative h-[125px]  ">
				<a
					tabIndex="0"
					href="#section2"
					className="skip-link absolute left-1/2 translate-x-[-50%] z-[100] top-[-100px] ease-in-out duration-300 rounded-full px-[10px] py-[8px] bg-black text-white"
				>
					Skip to Main Content
				</a>

				<div className="w-[85%] mx-auto h-[100%] flex justify-between items-center">
					<div className="relative w-[400px] h-[100%]">
						<Link
							tabIndex={navOpen ? "-1" : "0"}
							aria-label="Return to homepage"
							href="/"
						>
							<Image
								className="object-contain w-[100%] h-[auto] "
								priority={true}
								src={options.acf.logo.url}
								fill
								alt={options.acf.logo.alt}
							/>
						</Link>
					</div>

					<div className="flex flex-row gap-10">
						<Link
							className="block"
							tabIndex={navOpen ? "-1" : "0"}
							aria-label="Search for specific articles or pages."
							href="/search"
						>
							<CiSearch
								className="cursor-pointer min-[1000px]:block hidden "
								size={40}
								color={"#000000"}
							/>
						</Link>
						<button
							tabIndex={navOpen ? "-1" : "0"}
							aria-label="Expand the navigation"
							onClick={() => setNavOpen(true)}
						>
							<CiMenuBurger
								className="cursor-pointer"
								size={40}
								color="#000000"
							/>
						</button>
					</div>
				</div>
			</nav>

			{/* OPEN NAV DESKTOP*/}
			<div
				role="navigation"
				className={
					navOpen
						? "bigNav fixed top-0 left-0 h-[120vh] lg:h-[100vh] w-[100%] bg-white z-[100]  flex-col flex  min-[1000px]:flex-row transitionMe lg:overflow-y-hidden overflow-y-scroll"
						: "bigNav overflow-y-scroll transitionMe fixed top-[0%] left-[-100%]  lg:h-[100vh] h-[120vh] w-[100%] bg-white z-[100] flex flex-col min-[1000px]:flex-row"
				}
			>
				{/* CLOSE AND SEARCH ICONS*/}
				<div className="min-[1000px]:flex hidden  absolute h-[125px] justify-center items-center w-[auto] right-[7.5%]  flex-row gap-10">
					<Link
						tabIndex={navOpen ? "0" : "-1"}
						onClick={() => setNavOpen(false)}
						aria-label="Search for specific articles or pages."
						href="/search"
					>
						<CiSearch
							className="cursor-pointer min-[1000px]:block hidden "
							size={40}
							color="#000000"
						/>
					</Link>
					<button
						tabIndex={navOpen ? "0" : "-1"}
						aria-label="Close the navigation"
						onClick={() => setNavOpen(false)}
					>
						<TfiClose className="cursor-pointer" size={40} color="#000000" />
					</button>
				</div>

				{/*MAIN MENU ITEMS*/}
				<div
					className={
						navOpen
							? "desktopNav min-[1000px]:flex hidden bg-white w-[40%] min-[1000px]:w-[35%] min-[1400px]:w-[25%] h-[100%]  flex-col items-center gap-9"
							: " hidden bg-white w-[40%] min-[1000px]:w-[35%] min-[1400px]:w-[25%] h-[100%]  flex-col items-center gap-9 "
					}
				>
					<Link
						className="px-[20px]"
						tabIndex={navOpen ? "0" : "-1"}
						aria-label="Return to Homepage."
						href="/"
					>
						<Image
							onClick={() => setNavOpen(false)}
							priority={true}
							src={options.acf.logo.url}
							width={400}
							height={100}
							className="  my-[25px] navItem"
							alt={options.acf.logo.alt}
						/>
					</Link>
					{data.items.map((menuItem) => (
						<div
							aria-label={menuItem.title}
							key={menuItem.slug}
							className="navItem w-[60%]"
						>
							<Link
								onClick={!menuItem.child_items ? () => setNavOpen(false) : null}
								className={
									parentPage !== menuItem.object_id
										? currentRoute === menuItem.slug
											? "text-[24px] text-[#303030] font-bold transitionMe hover:opacity-50  underline underline-offset-4"
											: "text-[24px] text-[#303030] font-semibold transitionMe hover:opacity-50"
										: "text-[24px] text-[#303030] font-bold transitionMe hover:opacity-50  underline underline-offset-4"
								}
								href={new URL(menuItem.url).pathname}
							>
								{menuItem.title}
							</Link>
						</div>
					))}
				</div>

				{/* CHILDREN ITEMS*/}
				<div className="desktopNavChildren min-[1000px]:flex hidden bg-[#F7F7F7] w-[60%] min-[1000px]:w-[75%] h-[100%]  justify-center items-center">
					<ul className="!pl-0 flex flex-col gap-x-[15px] flex-wrap h-[70%] w-[70%]">
						{childrenMap}
					</ul>
				</div>

				{/* OPEN NAV MOBILE*/}

				<nav className=" relative !h-[125px] bg-transparent  flex min-[1000px]:hidden">
					<div className="w-[85%] mx-auto h-[125px] flex justify-between items-center gap-10">
						<div className="relative w-[400px] h-[100%]">
							<Link
								tabIndex={navOpen ? "-1" : "0"}
								aria-label="Return to homepage"
								href="/"
							>
								<Image
									className="object-contain w-[100%] h-[auto] "
									priority={true}
									src={options.acf.logo.url}
									fill
									alt={options.acf.logo.alt}
								/>
							</Link>
						</div>

						<div className="flex flex-row gap-10">
							<button
								tabIndex={navOpen ? "0" : "-1"}
								aria-label="Close the navigation"
								onClick={() => setNavOpen(false)}
							>
								<TfiClose
									onClick={() => setNavOpen(false)}
									className="cursor-pointer"
									size={40}
									color="#000000"
								/>
							</button>
						</div>
					</div>
				</nav>

				<div className="w-[100%] h-[100%] flex flex-col justify-start items-center overflow-y-scroll pb-[150px] lg:pb-[0px] min-[1000px]:hidden">
					<Accordion
						allowZeroExpanded
						className="w-[85%] mb-[100px]"
						preExpanded={[currentRoute]}
					>
						<AccordionItem
							className={
								currentRoute === "search"
									? "active accordionItem "
									: "accordionItem inactive"
							}
						>
							<AccordionItemHeading
								onClick={() => {
									currentRoute != "search" ? setNavOpen(false) : null;
								}}
								className="bg-[#fff] text-[#000] my-[25px] py-[10px] border-b-[1px] border-[#464646]"
							>
								<AccordionItemButton className="flex flex-row bg-[#fff] text-[#000] ">
									<Link
										aria-label={"search button"}
										className="bg-[#fff] text-[#000]  w-[100%] font-semibold px-[10px] text-[24px]"
										href={"/search"}
									>
										Search
									</Link>
									<AccordionItemState>
										{({ expanded }) =>
											expanded ? (
												<TfiAngleLeft
													className="cursor-pointer"
													size={24}
													color="#000000"
												/>
											) : (
												<TfiAngleRight
													className="cursor-pointer"
													size={24}
													color="#000000"
												/>
											)
										}
									</AccordionItemState>
								</AccordionItemButton>
							</AccordionItemHeading>
						</AccordionItem>

						{data.items.map((menuItem) => (
							<Fragment key={menuItem.slug}>
								<AccordionItem
									id={menuItem.slug}
									uuid={menuItem.slug}
									className={
										currentRoute === menuItem.slug
											? "active accordionItem "
											: "accordionItem inactive"
									}
								>
									<AccordionItemHeading
										onClick={
											!menuItem.child_items ? () => setNavOpen(false) : null
										}
										className="bg-[#fff] text-[#000] my-[25px] py-[10px] border-b-[1px] border-[#464646]"
									>
										<AccordionItemButton className="flex flex-row bg-[#fff] text-[#000] ">
											<Link
												aria-label={menuItem.title}
												className="bg-[#fff] text-[#000]  w-[100%] font-semibold px-[10px] text-[24px]"
												href={new URL(menuItem.url).pathname}
											>
												{menuItem.title}
											</Link>
											<AccordionItemState>
												{({ expanded }) =>
													expanded ? (
														<TfiAngleLeft
															className="cursor-pointer"
															size={24}
															color="#000000"
														/>
													) : (
														<TfiAngleRight
															className="cursor-pointer"
															size={24}
															color="#000000"
														/>
													)
												}
											</AccordionItemState>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<ul className="!pl-0">
											{menuItem.child_items
												? menuItem.child_items.map((childItem) => {
														return (
															<li
																className="list-none indent-0	"
																key={childItem.slug + childItem.url}
																id={childItem.slug + childItem.url}
															>
																<Link
																	aria-label={childItem.title + " Page."}
																	onClick={() => setNavOpen(false)}
																	className={
																		currentRoute === childItem.slug
																			? "bg-[#fff] text-[#000] w-[100%] py-[10px] border-b-[1px] border-[#464646] px-[10px] block text-[18px] font-bold"
																			: " bg-[#fff] text-[#000] w-[100%] py-[10px] border-b-[1px] border-[#464646] px-[10px] block text-[18px]"
																	}
																	href={childItem.slug ?? childItem.url}
																>
																	<span
																		dangerouslySetInnerHTML={{
																			__html: childItem.title,
																		}}
																	/>
																</Link>
															</li>
														);
													})
												: null}
										</ul>
									</AccordionItemPanel>
								</AccordionItem>
							</Fragment>
						))}
					</Accordion>
				</div>
			</div>
		</>
	);
};
